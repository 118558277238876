.navs_aside {
    background-color: #fff;
    display: flex !important;
}

.nav___item {
    width:100% ;
    .tab_link {
        text-align: left;
        justify-content: left;
    }
}

.tab_link {
    border-radius: 0 !important;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0px;
    color: #000 !important;
    height: 45px;
    width: 100%;
    text-align: left;
    display: flex !important;
    align-items: center;
    padding-left: 28px !important;
    &:hover {
        color: #000 !important;
    }
    a {
        padding: 0;
    }
    &.active {
        background-color: #4C8866 !important;     
        color: #fff !important;
    }
}

.sticky {
    top: 70px !important;
    z-index: 5555;
}

.css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 0 !important;
    height: 45px;
    box-shadow: none !important;
}

.css-1uccc91-singleValue{
    font-size: 16px !important;
}