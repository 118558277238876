.navs_aside {
    background-color: #fff;
    display: flex !important;
    
    border-bottom: 1px solid #f1f1f1;
}

.nav-item {
    width: calc(100% / 3);
}

.tab_link {
    border-radius: 0 !important;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0px;
    color: #000 !important;
    height: 45px;
    width: 100%;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    // padding-left: 28px !important;
    &:hover {
        color: #000 !important;
    }
    a {
        padding: 0;
    }
    &.active {
        background-color: #4C8866 !important;     
        color: #fff !important;
    }
}

.sticky {
    top: 70px !important;
    z-index: 5555;
}

.css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 0 !important;
    height: 45px;
    box-shadow: none !important;
}

.css-1uccc91-singleValue{
    font-size: 16px !important;
}


.head__title {
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    font-size: 24px;
    i {
        font-size: 28px !important;
        margin-top: 7px;
        margin-right: 10px;
    }
}
h2 {
    color: #000 !important;
    font-weight: 700 !important;
}
.input_cus {
    border-radius: 0px !important;
    height: 45px !important;
    border: 1px solid #DADADA !important;
    color: #000;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    &.color__name {
        border-left: 0px !important;
        text-transform: lowercase !important;
    }
    &.is-invalid  {
        border-color: #f46a6a !important;
    }
}

input[type=color] {
    height: 45px;
    border-right: 0px;
    border-radius: 0px;
    width: 10%;
}
input:disabled {
    background-color: #fff !important;
}

input[type=text],input[type=date],textarea {
    &::placeholder {
        color: #DADADA !important;
        font-size: 12px;
    }
}


select {
    cursor: pointer;
}
textarea {
    border-radius: 0px !important;
    border: 1px solid #DADADA ;
    color: #000;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: 500 !important;
}

label {
    color: #000;
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 14px;
    position: relative;
    margin-bottom: 5px !important;
    &.indec {
        color: #AFAFAF !important;
    }
    &.req {
        &::after {
            content: '*';
            position: absolute;
            color: #ff3a3a;
            font-size: 19px;
            left: 100%;
            top: -6px;
        }
    }
}

form {
    border-bottom: 1px solid #ECECEC !important;
    padding-bottom: 40px;
    display: block;
}


.submit__btn {
    position: fixed !important;
    z-index: 1 !important;
    background-color: #fff !important;
    display: flex;
    align-items: center;
    &.footer {
        background-color: #fff !important;
        box-shadow: 0 2px  10px rgba($color: #12263f08, $alpha: 0.2);
        height: 70px;
        margin: 0 !important;
        padding: 0;
    }
    button {
        width: 150px;
        height: 40px;
        margin-bottom: 2px;
        margin-left: 5px;
        background: none;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;
        &.cancel {
            border: 1px solid #AEAEAE;
        }
        &.save {
            border: 1px solid #3AAA35;
            background: #3AAA35;
            color: #fff;
        }
    }
}

.react-tag-input {
    padding: 0;
    border-radius: 0;
    padding-left: 10px;
}  

.react-tag-input__input {
    height: 45px;
    &::placeholder {
        color: #DADADA !important;
        font-size: 14px;
    }
}

.react-tag-input__tag__content {
    font-size: 16px;
}


.image__options {
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    gap: 10px;
    float: right;
    button {
        border: none;
        background-color: transparent;
    }
}


#react-select-2-input {
    height: 30px !important;
    border: none !important;
}

.select2-selection__control {
    border-radius: 0 !important;
    height: 44px;
    box-shadow: none;
}



.main-content {
    overflow: visible !important;
}